<template>
  <div>
    <form-wizard
      color="#9600ff"
      :title="null"
      :subtitle="null"
      layout="vertical"
      :finish-button-text="$t('Add')"
      :back-button-text="$t('Next')"
      :next-button-text="$t('Previous')"
      class="wizard-vertical mb-3"
      @on-complete="addMeetings"
    >
      <!-- account datails tab -->
      <tab-content style="padding-left: 15px; padding-right:20px;padding-top: 5px" title="Project informations">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h3 class="mb-0">  {{ $t('Meeting') }}</h3>
            <small class="text-muted">
                {{ $t('General_information_about_the_Meeting') }}
            </small>
          </b-col>
          
           <b-col md="6">
            <b-form-group label="" label-for="v-nom">
              <h5> {{ $t('Meeting_title') }}</h5>
              <b-form-input  id="v-nom" placeholder="Meeting title" v-model="meeting.meetingName" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" label-for="v-nom">
              <h5> {{ $t('Meeting_duration') }}</h5>
               <v-select
               multiple
              v-model="meeting.duration"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
              placeholder="Select meeting duration"
          />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="" label-for="v-description">
              <h5>Description</h5>
              <b-form-textarea
               v-model="meeting.description"
                id="v-description"
                placeholder="Meeting description..."
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" label-for="start">
              <h5>  {{ $t('startDate') }}</h5> 
              <b-form-datepicker
                  placeholder="Date"
                  v-model="meeting.startDate"
                  id="start"
                  :max="maxEndDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" label-for="end">
              <h5>{{ $t('EndDate') }}</h5>
              <b-form-datepicker
                  placeholder="Date"
                  v-model="meeting.endDate"
                  id="end"
                  :min="minEndDate"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

    
    </form-wizard>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BFormFile,
  BImg,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormRadioGroup,
    BForm,
    BButton,
    BImg,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      option: [{ title: '15 min',value:'15' }, { title: '30 min',value:'30' }, { title: '45 min',value:'45' }, { title: '60 min',value:'60' }],
      meeting: {
        user: JSON.parse(localStorage.getItem("userData"))._id,
        meetingName:"",
        description:'',
        startDate: "",
        endDate: "",
        duration: []
      },
      minEndDate: null,
      maxEndDate: null
    };
  },
  watch: {
    'meeting.startDate': function (newStartDate) {
      this.minEndDate = newStartDate;
    },
    'meeting.endDate': function (newStartDate) {
      this.maxEndDate = newStartDate;
    },
  },
  methods: {
    addMeetings() {
      if (Object.values(this.meeting).some((value) => value === null || value === "") || this.meeting.duration.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Complete the form to proceed",
            icon: "EditIcon",
            variant: "warning",
          },
        });      
      }
      else {
        this.meeting.duration.forEach((element, index) => {
          this.meeting.duration[index] = element.value;
        });
        axios.post("https://backendapinodejs.timecheckit.com/api/meetings",this.meeting)
          .then((response) => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Meeting added",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push('/apps/meetings');
          })
          .catch((error) => {
            console.log(error)
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "error during action",
                icon: "EditIcon",
                variant: "danger",
              },
            });
            });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
